<template>
  <NuxtLink
    v-if="to"
    :class="{ 'is-external': isExternal }"
    :target="`${isExternal ? '_blank' : ''}`"
    :to="to"
    :prefetch="prefetch"
    :external="isExternal"
  >
    <slot />
    <Icon
      v-if="withExternalIcon && isExternal"
      class="icon icon-external"
      name="fa6-solid:arrow-up-right-from-square"
    />
  </NuxtLink>
  <a v-else :href="to" data-msg="No url provided"><slot /></a>
</template>

<script lang="ts" setup>
const props = defineProps<{
  to: string
  prefetch?: boolean
  showExternalIcon?: boolean
}>()

const withExternalIcon = computed(() => {
  if (props.showExternalIcon === undefined) return true
  return props.showExternalIcon
})

const isExternal = computed(() => {
  try {
    const url = new URL(props.to)
    return !!url.host
  } catch (_) {}

  return false
})
</script>

<!-- @node: not-scoped! These styles are global -->
<style lang="postcss">
.fa-external-link-alt {
  display: inline-block;
  vertical-align: super;
  font-size: 0.5em;

  .page-header &,
  .page-footer &,
  .grid-header &,
  .card &,
  .img-link & {
    display: none;
  }

  .show-external-icon > & {
    display: inline-block;
  }

  .show-external-icon-md > & {
    display: inline-block;
    vertical-align: initial;
    font-size: 1em;
  }
}
</style>
